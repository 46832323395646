document.addEventListener("DOMContentLoaded", function () {
  const navbar = document.getElementById("navbar");
  const navlinks = navbar.querySelector("ul");
  const links = navbar.querySelectorAll("ul a");
  const toggleButton = document.getElementById("menu-toggle");

  let mobileOpen = false;

  for (var i = 0; i < links.length; i++) {
    if (window.location.href == links[i].href) {
      links[i].classList.add("active");
    }
  }

  function onMenuToggleClick() {
    if (mobileOpen) {
      mobileOpen = false;
      toggleButton.classList.remove("close");
      navlinks.classList.add("hidden");
      navbar.classList.remove("bg-primary-base");
      navbar.classList.add("bg-transparent");
      onScrollHandler();
    } else {
      mobileOpen = true;
      toggleButton.classList.add("close");
      navlinks.classList.remove("hidden");
      navbar.classList.add("bg-primary-base");
      navbar.classList.remove("bg-transparent");
      navbar.classList.remove("has-scrolled");
    }
  }

  function onScrollHandler() {
    if (window.scrollY > 40 && !mobileOpen) {
      navbar.classList.add("has-scrolled");
    } else {
      navbar.classList.remove("has-scrolled");
    }
  }


  window.addEventListener("scroll", onScrollHandler);
  toggleButton.addEventListener("click", onMenuToggleClick);

  // if the page was re-loaded, the scroll position might be somewhere in the
  // middle of the page. This ensures the correct navbar is displayed.
  onScrollHandler();

});
